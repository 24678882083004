import React from 'react';
import { Layout, Button } from 'components';
import './thankyou.scss';

const Thankyou = () => (
  <Layout>
    <seciton className="thankyou">
      <div className="wrapper">
        <div className="thankyou-headings">
          <h1>Thank you for getting in touch!</h1>
          <h4>Your submission is being reviewed and we will be in touch as soon as possible</h4>
        </div>
        <div className="thankyou-button-container">
          <Button to="/" theme="dark" className="thankyou-button">
            Back to home
          </Button>
        </div>
      </div>
    </seciton>
  </Layout>
);

export default Thankyou;
